import { Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
var ɵ0 = function () {
    return import("./modules/login/login.module.ngfactory").then(function (m) { return m.LoginModuleNgFactory; });
}, ɵ1 = function () {
    return import("./modules/presc-comerciales/presc-comerciales.module.ngfactory").then(function (m) { return m.PrescComercialesModuleNgFactory; });
}, ɵ2 = function () {
    return import("./modules/inicio/inicio.module.ngfactory").then(function (m) { return m.InicioModuleNgFactory; });
}, ɵ3 = function () {
    return import("./modules/presc-mapfre/presc-mapfre.module.ngfactory").then(function (m) { return m.PrescriptoresModuleNgFactory; });
}, ɵ4 = function () {
    return import("./modules/presc-correos/presc-correos.module.ngfactory").then(function (m) { return m.PrescCorreosModuleNgFactory; });
}, ɵ5 = function () {
    return import("./modules/presc-digitales/presc-digitales.module.ngfactory").then(function (m) { return m.PrescDigitalesModuleNgFactory; });
};
var routes = [
    { path: "", redirectTo: "login", pathMatch: "full" },
    {
        path: "login",
        loadChildren: ɵ0,
    },
    {
        path: "presc-comerciales",
        loadChildren: ɵ1,
        canActivate: [AuthGuard],
    },
    {
        path: "inicio",
        loadChildren: ɵ2,
        canActivate: [AuthGuard],
    },
    {
        path: "presc",
        loadChildren: ɵ3,
        canActivate: [AuthGuard],
    },
    {
        path: "presc-correos",
        loadChildren: ɵ4,
        canActivate: [AuthGuard],
    },
    {
        path: "presc-digital",
        loadChildren: ɵ5,
        canActivate: [AuthGuard]
    }
];
/**
 * AppRoutingModule
 * Designed to be the root routing module.
 *
 * imports:
 *  - RouterModule. Adds router directives and providers.
 *
 * exports:
 *  - RouterModule. The module with the configured routes.
 */
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
