// imports
import { HttpBareClient } from "@ng-darwin/security";
import { ConfigService } from "@ng-darwin/config";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LogService extends DataService {
  // constructor
  constructor(
    public http: HttpClient,
    public httpBare: HttpBareClient,
    public config: ConfigService
  ) {
    super(http, httpBare, config);
  }

  /**
   * metodo que sirve
   * para crear un log del tipo de evento
   */
  createObjLog(eventType: string) {
    const log = {
      usuario: "",
      date: "",
      tipoEvento: eventType,
    };
    return log;
  }
  /**
   * metodo que sirve para enviar
   * los datos a kibana
   */
  sendKibana(log: any) {
    this.url = this.endPoints.log;
    this.create(log);
  }
}
