import * as tslib_1 from "tslib";
import { DataService } from "./data.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ng-darwin/security";
import * as i3 from "@ng-darwin/config";
var UserService = /** @class */ (function (_super) {
    tslib_1.__extends(UserService, _super);
    function UserService(http, httpBare, config) {
        var _this = _super.call(this, http, httpBare, config) || this;
        _this.http = http;
        _this.httpBare = httpBare;
        _this.config = config;
        return _this;
    }
    /**
     * Metodo que retorna los datos del prescirptor
     * @returns Observable<any>
     */
    UserService.prototype.getContactData = function () {
        this.url = this.endPoints.datosPrescriptor;
        return this.getData();
    };
    /**
     * Metodo encargado de devolver los datos
     *  con informacion del prescriptor
     * @returns Observable<any>
     */
    UserService.prototype.getBasicUserData = function () {
        this.url = this.endPoints.datosPrescriptor;
        return this.getData();
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpBareClient), i0.ɵɵinject(i3.ConfigService)); }, token: UserService, providedIn: "root" });
    return UserService;
}(DataService));
export { UserService };
