import { HttpBareClient } from "@ng-darwin/security";
import { ConfigService } from "@ng-darwin/config";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService extends DataService {
  constructor(
    public http: HttpClient,
    public httpBare: HttpBareClient,
    public config: ConfigService
  ) {
    super(http, httpBare, config);
  }

  /**
   * Metodo que retorna los datos del prescirptor
   * @returns Observable<any>
   */
  getContactData(): Observable<any> {
    this.url = this.endPoints.datosPrescriptor;
    return this.getData();
  }

  /**
   * Metodo encargado de devolver los datos
   *  con informacion del prescriptor
   * @returns Observable<any>
   */
  getBasicUserData(): Observable<any> {
    this.url = this.endPoints.datosPrescriptor;
    return this.getData();
  }
}
