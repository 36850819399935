import * as tslib_1 from "tslib";
import { ENDPOINTS } from "./../../environments/environment";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { SessionService } from "./session.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ng-darwin/security";
import * as i3 from "@ng-darwin/config";
// insertamos la vble para el root
var DataService = /** @class */ (function (_super) {
    tslib_1.__extends(DataService, _super);
    // constructor del modulo
    function DataService(http, httpBare, config) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.httpBare = httpBare;
        _this.config = config;
        // showBackground
        _this.background = new BehaviorSubject("");
        _this.background$ = _this.background.asObservable();
        _this.formCorreos = new BehaviorSubject({
            personType: "",
            name: "",
            phone: "",
            email: "",
            productType: "",
            productDesc: "",
            office: "",
            officeDesc: "",
            attentionModel: "",
            preferenceHour: "",
            preferenceDate: "",
            signType: "",
            observations: "",
            sign: "",
        });
        _this.formCorreos$ = _this.formCorreos.asObservable();
        // variable con la configuracion base
        _this.base = _this.config.config.app.base;
        _this.endPoints = ENDPOINTS;
        _this.url = "";
        _this.httpHeaders = new HttpHeaders({
            "X-Santander-Channel": "INT",
            "Content-Type": "application/json",
        });
        // Parametros del HTTPHeader
        _this.params = new HttpParams();
        _this.httpClientMethod = _this.http;
        _this.usuarioPC = _this.config.config.app.usuarioPcExcepcionado;
        _this.usuarioBP = _this.config.config.app.usuarioBpExcepcionado;
        _this.entorno = _this.config.config.app.environment;
        return _this;
    }
    /**
     * Metodo para obtener por el
     * get una peticion al API
     * @returns Observable
     */
    DataService.prototype.getData = function () {
        var _this = this;
        return this.httpClientMethod
            .get(this.base + this.url, {
            headers: this.httpHeaders,
            params: this.params,
        })
            .pipe(map(function (res) {
            // devuelve el resultado
            return _this.processHttpResponse(res);
        }));
    };
    // clase para crear elementos
    DataService.prototype.create = function (body) {
        var _this = this;
        return this.httpClientMethod
            .post(this.base + this.url, body, {
            headers: this.httpHeaders,
            params: this.params,
        })
            .pipe(map(function (res) {
            return _this.processHttpResponse(res);
        }));
    };
    // clase para resetear pass
    DataService.prototype.reset = function (body) {
        var _this = this;
        return this.httpClientMethod
            .patch(this.base + this.url, body, {
            headers: this.httpHeaders,
            params: this.params,
        })
            .pipe(map(function (res) {
            return _this.processHttpResponse(res);
        }));
    };
    // clase para actualizar elementos
    DataService.prototype.update = function (body) {
        var _this = this;
        return this.httpClientMethod
            .put(this.base + this.url, body, {
            headers: this.httpHeaders,
            params: this.params,
        })
            .pipe(map(function (res) {
            // devuelve el resultado
            return _this.processHttpResponse(res);
        }));
    };
    // clase para borrar elementos
    DataService.prototype.delete = function () {
        return this.httpClientMethod
            .delete(this.base + this.url, {
            headers: this.httpHeaders,
            params: this.params,
        })
            .pipe(map(function (res) {
            // devuelve el resultado
            return res;
            // return this.processHttpResponse(res);
        }));
    };
    /**
     * Metodo que procesa el RESPONSE de las peticiones HttpClien,
     * en este caso inicializamos los HttpParams luego de cada peticion
     * @param response: Object
     * @return response
     */
    DataService.prototype.processHttpResponse = function (response) {
        this.params = new HttpParams();
        // devuelve el resultado
        return response;
    };
    /**
     * setstorage function
     */
    DataService.prototype.setStorage = function (name, value) {
        return sessionStorage.setItem(name, value);
    };
    /**
     * getstorage function
     */
    DataService.prototype.getStorage = function (name) {
        return sessionStorage.getItem(name);
    };
    DataService.prototype.setBackground = function (type) {
        this.background.next(type);
    };
    DataService.prototype.setFormCorreos = function (type) {
        this.formCorreos.next(type);
    };
    DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpBareClient), i0.ɵɵinject(i3.ConfigService)); }, token: DataService, providedIn: "root" });
    return DataService;
}(SessionService));
export { DataService };
