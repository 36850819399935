import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from "@angular/core";
var AppComponent = /** @class */ (function () {
    /**
     * Several services are injected into the constructor for the examples
     * The only mandatory service is securityService if you want the token to be managed by Darwin
     */
    function AppComponent(securityService, loggerService, httpBareClient, layoutService, dataService, cdRef) {
        this.securityService = securityService;
        this.loggerService = loggerService;
        this.httpBareClient = httpBareClient;
        this.layoutService = layoutService;
        this.dataService = dataService;
        this.cdRef = cdRef;
        this.alertShow = false;
        this.headerShow = false;
        this.footerShow = false;
        this.alertOptions = {};
        this.claseBackground = "ps--full-image-container";
        this.countDown = 0;
    }
    AppComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // THIS TWO LINES OF CODE ARE MANDATORY to manage the session and token:
                // - Subscription to session events (aboutToTimeout, timeout, resumed)
                // - Initialization of security service to manage the session and token
                // this._subcribeToSessionEvents();
                //  VERY IMPORTANT!!!
                //  PUT YOUR CODE HERE, AFTER SECURITY INITIALIZE PROMISE HAS BEEN FULLFILLED
                // **************************************************************************
                // this._logMessages();
                this.layoutSubscribers();
                this.backgroundMatch("");
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.ngAfterViewChecked = function () {
        this.cdRef.detectChanges();
    };
    AppComponent.prototype.backgroundMatch = function (type) {
        var _this = this;
        this.dataService.background$.subscribe(function (response) {
            switch (response) {
                case "correos":
                    _this.claseBackground = "ps--full-image-container-correos";
                    break;
                case "login":
                    _this.claseBackground = "ps--full-image-container";
                    break;
                case "mapfre":
                    _this.claseBackground = "ps--full-image-container-mapfre";
                    break;
                case "sinfondo":
                    _this.claseBackground = "ps--full-container";
                    break;
                case "comerciales":
                    _this.claseBackground = "ps--full-image-container-comerciales";
                    break;
                case "bp":
                    _this.claseBackground = "ps--full-image-container-bancaprivada";
                    break;
                default:
                    _this.claseBackground = "ps--full-image-container";
                    break;
            }
            _this.cdRef.detectChanges();
        });
    };
    AppComponent.prototype.layoutSubscribers = function () {
        var _this = this;
        this.layoutService.alertOptions$.subscribe(function (options) {
            _this.alertOptions = options;
        });
        this.layoutService.alertIsShowed$.subscribe(function (show) {
            _this.alertShow = show;
        });
        this.layoutService.headerIsShowed$.subscribe(function (show) {
            setTimeout(function () { return (_this.headerShow = show); });
        });
        this.layoutService.footerIsShowed$.subscribe(function (show) {
            _this.footerShow = show;
        });
    };
    /**
     * Method for a button click event inside the view
     */
    AppComponent.prototype.getRequestThroughHttpBareClient = function () {
        var _this = this;
        // Important: Note that this request through httpBareClientService has no interceptors. It's a bare request
        this.httpBareClient
            .get("/api/alive", { responseType: "text" })
            .subscribe(function (value) {
            console.log("alive response:", value);
            _this.response = value;
        });
    };
    /**
     * Method for a button click event inside the view
     */
    AppComponent.prototype.postRequestThroughLoggerService = function () {
        var _this = this;
        // Important: Note this request always have common headers if Security module has been loaded,
        // but only have auth header if 'logger.isSecure' property is true
        this.loggerService
            .logError({
            log: "my message to be logged",
            component: "mycomponent",
        })
            .then(function () {
            _this.response =
                "Log enviado a fake Data Base. Se ha creado un nuevo registro en el fichero db.json";
        });
    };
    /**
     * Method for close session and clean credentials
     */
    AppComponent.prototype.closeSession = function () {
        this.securityService.killSession();
        this.response =
            "La sessión ha sido cerrada y las credenciales se han eliminado correctamente";
    };
    return AppComponent;
}());
export { AppComponent };
