// imports
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "../../components/header/header.component";
import { ModalBajaLeadComponent } from "../../components/modal-baja-lead/modal-baja-lead.component";

// tenemos que importar
// y exportar
@NgModule({
  declarations: [
    HeaderComponent,
    ModalBajaLeadComponent
  ],
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    ModalBajaLeadComponent
  ],
})
export class SharedModule {}
