<div class="modal show fade in" id="alertModal" tabindex="-1" role="dialog">
  <div class="modal-md modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content mx-auto">
      <button
        id="btn-close"
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeAlert()"
        (keypress)="closeAlert()"
        tabindex="0"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body text-center">
        <div *ngIf="classIcon" class="icon-main text-center mb-4">
          <span class="{{ classIcon }}"></span>
        </div>

        <div *ngIf="textDescription" class="text-description">
          {{ textDescription }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          id="btn-footer-modal"
          *ngIf="textBtnClose"
          type="button"
          class="btn ps--btn ps--btn-primary"
          (click)="closeAlert()"
          (keypress)="closeAlert()"
          tabindex="10"
        >
          {{ textBtnClose }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show in" aria-hidden="true"></div>
