import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ConfigModule } from "@ng-darwin/config";
import { SecurityModule } from "@ng-darwin/security";
import { LoggerModule } from "@ng-darwin/logger";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";

import { DatePipe, registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";

import { SharedModule } from "./modules/shared/shared.module";
import { AlertComponent } from "./components/alert/alert.component";
import { ModalComponent } from "./components/modal/modal.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsModalService, ModalModule } from "ngx-bootstrap/modal";
registerLocaleData(localeEs);

/**
 * AppModule
 * Designed to be the root module.
 *
 * imports:
 *  - BrowserModule. Required infrastructure for all Angular apps.
 *  - HttpClientModule. Configures the DI for HttpClient.
 *  - ConfigModule. Configures the DI for ConfigService..
 *  - SecurityModule. Configures the DI for SecurityService
 *  - LoggerModule. Configures the DI for LoggerService.
 *  - AppRoutingModule. Module with the configured routes
 *
 * declarations:
 *  - AppComponent. Root component in charge of being the app shell
 *
 * bootstrap:
 *   - AppComponent
 */
@NgModule({
  declarations: [AppComponent, AlertComponent, ModalComponent, FooterComponent],
  imports: [
    SharedModule,
    BrowserModule,
    ConfigModule.forRoot({
      logEvent: {
        level: environment.configLogLevel,
        handler(ev) {
          console.log("Log event from Config module:", ev);
        },
      },
      errorEvent: {
        handler(ev) {
          console.error("Error event from Config module:", ev);
        },
      },
      configLoadedEvent: {
        handler(ev) {
          console.log("ConfigLoaded event from Config module:", ev);
        },
      },
    }),
    SecurityModule,
    LoggerModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ModalModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es" },
    DatePipe,
    BsModalService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
