import * as tslib_1 from "tslib";
import { KEYS_SECONDARY_SEESSION } from "./../../environments/environment";
import * as i0 from "@angular/core";
var SessionService = /** @class */ (function () {
    function SessionService() {
    }
    /**
     * Metodo que guarda en la session datos requeridos para tener en contexto
     */
    SessionService.prototype.setStorage = function (key, value) {
        return sessionStorage.setItem(key, value);
    };
    /**
     * Metodo busca en session los datos requeridos para tener en contexto
     */
    SessionService.prototype.getStorage = function (key) {
        return sessionStorage.getItem(key);
    };
    /**
     * Elimina de la session el valor de la key que se le pase por parametro
     * @param key `string`
     */
    SessionService.prototype.clearStorage = function (key) {
        sessionStorage.removeItem(key);
    };
    /**
     * Borrar todos los datos del Storage,
     * metodo utilizada para limpiar la session del navegador,
     * y reiniciar todo el flujo del formulario
     * @return void
     */
    SessionService.prototype.clearDataInSession = function () {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(Object.entries(KEYS_SECONDARY_SEESSION)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                this.clearStorage(key[1]);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * Meotodo que gurada en session cuando un usuario esta logado
     * @param login: {token: string, user: string}
     */
    SessionService.prototype.setUserLoginInSession = function (login) {
        return this.setStorage(KEYS_SECONDARY_SEESSION.login, JSON.stringify(login));
    };
    /**
     * Meotodo que obtiene en session cuando un usuario esta logado
     * @param login: {token: string, user: string}
     */
    SessionService.prototype.getUserLoginInSession = function () {
        var userLoginSting = this.getStorage(KEYS_SECONDARY_SEESSION.login);
        return userLoginSting !== null ? JSON.parse(userLoginSting) : null;
    };
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
