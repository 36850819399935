import * as tslib_1 from "tslib";
import { LogService } from "./log.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ng-darwin/security";
import * as i3 from "@ng-darwin/config";
var HeaderService = /** @class */ (function (_super) {
    tslib_1.__extends(HeaderService, _super);
    function HeaderService(http, httpBare, config) {
        var _this = _super.call(this, http, httpBare, config) || this;
        _this.http = http;
        _this.httpBare = httpBare;
        _this.config = config;
        return _this;
    }
    HeaderService.prototype.getLogos = function (actuacion) {
        this.url = this.endPoints.logos;
        this.url = this.url.replace("{actuacion}", actuacion);
        this.httpClientMethod = this.http;
        return this.getData();
    };
    HeaderService.prototype.setAreaPersonal = function (telefono) {
        var phoneBody = {
            phone: telefono,
        };
        this.url = this.endPoints.areaPersonal;
        return this.reset(phoneBody);
    };
    HeaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpBareClient), i0.ɵɵinject(i3.ConfigService)); }, token: HeaderService, providedIn: "root" });
    return HeaderService;
}(LogService));
export { HeaderService };
