/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./components/header/header.component.ngfactory";
import * as i2 from "./components/header/header.component";
import * as i3 from "@angular/router";
import * as i4 from "@ng-darwin/security";
import * as i5 from "./services/header.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./services/layout.service";
import * as i8 from "./services/user.service";
import * as i9 from "./services/data.service";
import * as i10 from "./components/alert/alert.component.ngfactory";
import * as i11 from "./components/alert/alert.component";
import * as i12 from "./components/footer/footer.component.ngfactory";
import * as i13 from "./components/footer/footer.component";
import * as i14 from "@angular/common";
import * as i15 from "./app.component";
import * as i16 from "@ng-darwin/logger";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "prescp-header", [], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderComponent, [i3.Router, i4.SecurityService, i5.HeaderService, i6.DomSanitizer, i7.LayoutService, i8.UserService, i9.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "prescp-alert", [], null, null, null, i10.View_AlertComponent_0, i10.RenderType_AlertComponent)), i0.ɵdid(1, 573440, null, 0, i11.AlertComponent, [i7.LayoutService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertOptions; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "prescp-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i13.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i14.ɵNgClassImpl, i14.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i14.NgClass, [i14.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(4, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i0.ɵdid(8, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i0.ɵdid(10, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.claseBackground; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.headerShow; _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); var currVal_2 = _co.alertShow; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.footerShow; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "prescp-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 8503296, null, 0, i15.AppComponent, [i4.SecurityService, i16.LoggerService, i4.HttpBareClient, i7.LayoutService, i9.DataService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("prescp-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
