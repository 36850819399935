import { ROUTE_PATH } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ng-darwin/security";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(securityService, router) {
        this.securityService = securityService;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        return this.checkLogin(state.url);
    };
    AuthGuard.prototype.checkLogin = function (url) {
        if (this.securityService.token) {
            return true;
        } // Store the attempted URL for redirecting this.authService.redirectUrl = url;
        // Redirect to the login page
        return this.router.parseUrl(ROUTE_PATH.login);
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.SecurityService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
