import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { SecurityService } from "@ng-darwin/security";
import { Observable } from "rxjs";
import { ROUTE_PATH } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly securityService: SecurityService,
    private readonly router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string): true | UrlTree {
    if (this.securityService.token) {
      return true;
    } // Store the attempted URL for redirecting this.authService.redirectUrl = url;
    // Redirect to the login page
    return this.router.parseUrl(ROUTE_PATH.login);
  }
}
