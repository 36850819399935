import { Component, OnInit } from "@angular/core";

@Component({
  selector: "prescp-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit {
  mapfreText =
    ", accede a Service Manager utilizando la siguiente ruta: APLICACIONES > BANCASEGUROS > PRESCRIPCION SANTANDER, adjuntando la captura de pantalla con el error.";
  comercialesText =
    ", accede a ITSM, adjuntando la captura de pantalla con el error.";
  text: any;
  actuacion = sessionStorage.getItem("action");
  tipoPrescriptor = sessionStorage.getItem('tipoPresc');
  constructor() {}

  ngOnInit() {}

  checkPrescComGenerico(){
    if(this.tipoPrescriptor === 'comercial'){
      return true;
    }else{
      return false;
    }
  }

  checkPrescBancaPrivada(){
    if(this.tipoPrescriptor === 'privada'){
      return true;
    }else{
      return false;
    }
  }
}
