import { LayoutService } from "./../../services/layout.service";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "prescp-alert",
  templateUrl: "./alert.component.html",
})
export class AlertComponent implements OnChanges {
  classIcon?: string;
  textDescription?: string;
  textBtnClose?: string;

  @Input() options: {
    textDescription?: string;
    classIcon?: string;
    textBtnClose?: string;
  } = {};

  constructor(private readonly layoutService: LayoutService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.classIcon = changes.options.currentValue.classIcon;
      this.textDescription = changes.options.currentValue.textDescription;
      this.textBtnClose = changes.options.currentValue.textBtnClose;
    }
  }

  closeAlert() {
    this.layoutService.hideAlert();
  }
}
