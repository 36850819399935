import { KEYS_SECONDARY_SEESSION } from "./../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor() {}

  /**
   * Metodo que guarda en la session datos requeridos para tener en contexto
   */
  setStorage(key: string, value: string): void {
    return sessionStorage.setItem(key, value);
  }

  /**
   * Metodo busca en session los datos requeridos para tener en contexto
   */
  getStorage(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  /**
   * Elimina de la session el valor de la key que se le pase por parametro
   * @param key `string`
   */
  clearStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  /**
   * Borrar todos los datos del Storage,
   * metodo utilizada para limpiar la session del navegador,
   * y reiniciar todo el flujo del formulario
   * @return void
   */
  clearDataInSession(): void {
    for (const key of Object.entries(KEYS_SECONDARY_SEESSION)) {
      this.clearStorage(key[1]);
    }
  }

  /**
   * Meotodo que gurada en session cuando un usuario esta logado
   * @param login: {token: string, user: string}
   */
  setUserLoginInSession(login: { token: string; user: string }): void {
    return this.setStorage(
      KEYS_SECONDARY_SEESSION.login,
      JSON.stringify(login)
    );
  }

  /**
   * Meotodo que obtiene en session cuando un usuario esta logado
   * @param login: {token: string, user: string}
   */
  getUserLoginInSession(): { token: string; user: string } | null {
    const userLoginSting = this.getStorage(KEYS_SECONDARY_SEESSION.login);
    return userLoginSting !== null ? JSON.parse(userLoginSting) : null;
  }
}
