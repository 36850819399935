import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "@ng-darwin/config";
import { HttpBareClient } from "@ng-darwin/security";
import { Observable } from "rxjs";
import { LogService } from "./log.service";

@Injectable({
  providedIn: "root",
})
export class HeaderService extends LogService {
  constructor(
    public http: HttpClient,
    public httpBare: HttpBareClient,
    public config: ConfigService
  ) {
    super(http, httpBare, config);
  }

  getLogos(actuacion: any): Observable<any> {
    this.url = this.endPoints.logos;
    this.url = this.url.replace("{actuacion}", actuacion);
    this.httpClientMethod = this.http;
    return this.getData();
  }
  setAreaPersonal(telefono: any): Observable<any> {
    const phoneBody = {
      phone: telefono,
    };
    this.url = this.endPoints.areaPersonal;

    return this.reset(phoneBody);
  }
}
