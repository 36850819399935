import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var LayoutService = /** @class */ (function () {
    function LayoutService() {
        this._alertShow$ = new BehaviorSubject(false);
        this._headerShow$ = new BehaviorSubject(false);
        this._footerShow$ = new BehaviorSubject(false);
        this._alertOptions$ = new BehaviorSubject({});
        this._resetPass$ = new BehaviorSubject(false);
        this.alertIsShowed$ = this._alertShow$.asObservable();
        this.alertOptions$ = this._alertOptions$.asObservable();
        this.headerIsShowed$ = this._headerShow$.asObservable();
        this.footerIsShowed$ = this._footerShow$.asObservable();
        this.resetPassIsShowed$ = this._resetPass$.asObservable();
    }
    /**
     * Metodo para mostrar los errores contralorados en pantalla completa
     * @param message string
     * @param message IErrorOptionsç
     */
    LayoutService.prototype.showAlert = function (options) {
        this._alertShow$.next(true);
        this._alertOptions$.next(options);
    };
    /**
     * showHeader
     */
    LayoutService.prototype.showHeader = function () {
        this._headerShow$.next(true);
    };
    LayoutService.prototype.hideHeader = function () {
        this._headerShow$.next(false);
    };
    /**
     * mostrar footer
     */
    LayoutService.prototype.showFooter = function () {
        this._footerShow$.next(true);
    };
    /**
     * ocultar footer
     */
    LayoutService.prototype.hideFooter = function () {
        this._footerShow$.next(false);
    };
    /**
     * activar resetPass
     */
    LayoutService.prototype.showResetPass = function () {
        this._resetPass$.next(true);
    };
    /**
     * ocultar resetPass
     */
    LayoutService.prototype.hideResetPass = function () {
        this._resetPass$.next(false);
    };
    /**
     * Metodo para ocultar los errores contralorados en pantalla completa
     * @param message string
     * @param message IErrorOptions
     */
    LayoutService.prototype.hideAlert = function () {
        this._alertShow$.next(false);
        this._alertOptions$.next({});
    };
    LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
export { LayoutService };
