/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./alert.component";
import * as i3 from "../../services/layout.service";
var styles_AlertComponent = [];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "icon-main text-center mb-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.classIcon, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_AlertComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "text-description"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textDescription; _ck(_v, 1, 0, currVal_0); }); }
function View_AlertComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn ps--btn ps--btn-primary"], ["id", "btn-footer-modal"], ["tabindex", "10"], ["type", "button"]], null, [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.closeAlert() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textBtnClose; _ck(_v, 1, 0, currVal_0); }); }
export function View_AlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "modal show fade in"], ["id", "alertModal"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "modal-md modal-dialog modal-dialog-centered"], ["role", "document"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "modal-content mx-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["id", "btn-close"], ["tabindex", "0"], ["type", "button"]], null, [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.closeAlert() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body text-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_2)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_3)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 0, "div", [["aria-hidden", "true"], ["class", "modal-backdrop fade show in"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.classIcon; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.textDescription; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.textBtnClose; _ck(_v, 13, 0, currVal_2); }, null); }
export function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "prescp-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 573440, null, 0, i2.AlertComponent, [i3.LayoutService], null, null)], null, null); }
var AlertComponentNgFactory = i0.ɵccf("prescp-alert", i2.AlertComponent, View_AlertComponent_Host_0, { options: "options" }, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
