import * as tslib_1 from "tslib";
import { DataService } from "./data.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ng-darwin/security";
import * as i3 from "@ng-darwin/config";
var LogService = /** @class */ (function (_super) {
    tslib_1.__extends(LogService, _super);
    // constructor
    function LogService(http, httpBare, config) {
        var _this = _super.call(this, http, httpBare, config) || this;
        _this.http = http;
        _this.httpBare = httpBare;
        _this.config = config;
        return _this;
    }
    /**
     * metodo que sirve
     * para crear un log del tipo de evento
     */
    LogService.prototype.createObjLog = function (eventType) {
        var log = {
            usuario: "",
            date: "",
            tipoEvento: eventType,
        };
        return log;
    };
    /**
     * metodo que sirve para enviar
     * los datos a kibana
     */
    LogService.prototype.sendKibana = function (log) {
        this.url = this.endPoints.log;
        this.create(log);
    };
    LogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogService_Factory() { return new LogService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpBareClient), i0.ɵɵinject(i3.ConfigService)); }, token: LogService, providedIn: "root" });
    return LogService;
}(DataService));
export { LogService };
